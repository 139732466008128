.btn {
  line-height: 1.3rem;
  @extend .text_center;
  @extend .border_0;
  border-radius: 3px;
  @extend .cursor_pointer;
  font-family: Inter,sans-serif ;
  color: $white;
  padding: 0.625rem 1.938rem;
  background: $light_blue;
  @include transition(all .3s ease-in-out);
  font-weight: 600;
  //&:hover{
  //  background: rgba($light_blue, 0.7);
  //  color: $white;
  //}
}
.btn_default {
  line-height: 0.3rem;
  @extend .text_center;
  border-radius: 3px;
  @extend .cursor_pointer;
  padding: 0.625rem 1.938rem;
  min-width: 100px;
  height: 40px;
  background: #fff;
  border: 1px solid #7b849e;
  color: #7b849e;
  margin-right: 10px;
  font-family: Inter,sans-serif ;
}
.btn_primary {
  font-size: 1.4rem;
  line-height: 2rem;
  @extend .text_center;
  @extend .border_0;
  @extend .radius_10;
  @extend .cursor_pointer;
  color: $white;
  padding: 1.1rem 2.5rem;
  min-width: 15.4rem;
  background: $maroon;
  font-family: Inter,sans-serif ;
  font-weight: 600;
  @include transition(all .3s ease-in-out);
  &:hover{
    background: #9f0622;
  }
}

.profile-image-dropdown{
  position: relative;
  .close-icon{
    position: absolute;
    top: -7px;
    left: 85px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 88;
    background: #de2b2b;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      width: 89%;
      height: 89%;
    }
    image{
      width: 100%;
      height: 100%;
    }
  }
}
.popular-game-main-section{
  .profile-image-dropdown.popularGame_close{
    .close-icon{
      right: -7px;
      top: 16px;
      left: auto;
    }
  }
}
.profile-image-dropdown.avatar-profile{
  .close-icon{
    right: 3px;
    top: 1px;
    left: auto;
  }
}
.ads_internal{
  .close-icon{
    right: -5px;
    top: 21px;
    left: auto;
  }
}
.header_slider_details_Ads_videoTicket{
  input{
    display: flex;
    align-items: stretch;
    min-height: 48px;
    border-radius: 6px;
    padding: 0 16px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    background-color: white;
    border: 1px solid #cfd8e3;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 7px;
  }
}
.how-to-play-section{
  .game_details_slider{
    height: auto;
  }
  .ag_gameSection .ag_main .slick-slider img {
    padding: 0 10px;
    height: 228px;
    width: 100%;
    object-fit: cover;
  }
}

.game-mode-icon-div{
  position: relative;
   .close-icon {
     align-items: center;
     background: #de2b2b;
     border-radius: 50%;
     color: #fff;
     cursor: pointer;
     display: flex;
     height: 20px;
     justify-content: center;
     position: absolute;
     right: -6px;
     top: -7px;
     width: 20px;
     z-index: 88;
     svg {
       height: 89%;
       width: 89%;
     }
   }
}
.sendMessage_btn{
  height: 52px;
  margin-top: -11px;
}