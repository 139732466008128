

.login_section{
    height: 100%;
  overflow: hidden;
  .bg_black{
    background-color: #0c0d13;
    text-align: left;
    img{
      margin-left: -56px;
    }
  }
}
.login_container {
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 60px;
  background: $login_Bg;
  .login_form {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    padding: 48px;
    background: $white;
    .Login_Title {
      padding-left: 8rem;
      margin-bottom: 32px;
      font-size: 30px !important;
      font-weight: 800 !important;
      letter-spacing: -0.022em !important;
      line-height: 1.25 !important;
    }
    .forgetContainer {
      margin-top: 16px;
      .rememberWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          background: none;
          border: none;
          cursor: pointer;
        }
      }
      .checkWrap {
        margin: 0 8px 0 0;
        display: flex;
        label {
          padding-left: 14px;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 1.125 !important;
          letter-spacing: normal;
          color: #0c0d13;
        }
        input {
          width: 20px;
          height: 20px;
          margin: 0;
          display: block;
          border: 1px solid #cfd8e3;
          outline: none;
          font-style: normal;
          font-size: 12px;
          line-height: 14px;
          color: #000;
          background: white;
          padding: 12px 11px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .formloginBtn {
      margin: 27px auto 0;
      padding: 16px;
      max-width: 320px;
      width: 100%;
      border-radius: 5px;
      border: transparent;
      background: #2977bd;
      font-size: 14px;
      font-weight: 500;
      color: #f0f5ff;
      cursor: pointer;
      font-family: "Inter", sans-serif !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
    .formData {
      .sd_password_eye_open {
        cursor: pointer;
      }
      label {
        color: #27303f ;
        font-weight: 500 ;
        font-size: 14px ;
        line-height: 18px;
      }
      input {
        outline-style: none;
        padding: 12px 10px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #cfd8e3;
        margin: 8px 0 5px;
        height: 48px;
      }
      .passWrap {
        margin: 8px 0 5px;
        display: block;
        width: 100%;
        border: 1px solid #cfd8e3;
        outline: none;
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: #000;
        background: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        .passInput {
          width: 100%;
          display: flex;
          height: 48px;
          input {
            width: 100%;
            border: none;
            border-radius: 0;
            margin: 0;
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            color: #000;
            background: transparent;
          }
          .sd_passoword_toggle {
            border: none;
            width: 20px;
            margin-right: 15px;
            background: transparent !important;
            min-width: auto !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
              cursor: pointer;
              color: #1d1f2b;
            }
          }
        }
      }
    }
  }
}
.sd_passoword_toggle{
  svg{
    cursor: pointer;
    color: #1d1f2b;
  }
}
.forgot-password{
  button{
    font-family: "Inter", sans-serif !important;
  }
}

.platform_report_filter{
  .filter_days_details_dropDown{
    margin-bottom: 0 !important;
  }
}

.side_logo_box {
  background-color: #0c7cfc;
  margin-top: -10px;

}
.side_logo img {
  height: 75px;
  margin-bottom: 5px;
  margin-top: 5px;
  object-fit: cover;
  width: 100%;
}

.login_section .login_section_logo {
  background-color: #0c7cfc;
  width: 281px;
}
.login_section .login_section_logo img {
  margin: 10px 0;
}
.css-14qhad2, .dashboard-section {
  padding: 80px 32px;
  text-align: center;
  background-color: rgb(12, 124, 252);
}
.dashboard-section .Logo {
  height: 250px;
  margin: 0 auto;
  object-fit: contain;
}