.mgp_release_section {
  .rollOut_percentage_dropdown {
    .filter_days_details_dropDown.gst-dropdown_width {
      width: 100%;
      margin-top: 7px;
    }
  }
  .mgp_release_inner_section {
    padding: 32px 0;
    .add_admin_user_popup_content {
      padding: 0 32px;
      height: 64vh;
      overflow: auto;
    }
  }
  .select_game_option_mode .select_game_option {
    margin-top: 5px;
  }
  .user_kyc_section_filed {
    margin-top: 10px;
  }
  .user_kyc_section_filed_document {
    margin: 5px 0 10px;
    .document_details_kyc {
      width: 100%;
      .u_file-attachment {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-shadow: none;
    width: 18px;
    min-height: auto;
    height: 18px;
    margin-right: 5px;
    cursor: pointer;
  }
  .d-flex-wrap {
    display: flex;
    .form_data_field {
      display: flex;
      align-items: center;
    }
    .tab_no {
      margin-left: 10px;
    }
  }
}
.bank-icon-details {
  .user_profile {
    margin-left: unset;
  }
}

.application_build {
  .application_build_dropdown {
    .filter_export_details_dropDown {
      margin-top: 7px;
    }
  }
  .user_kyc_section_filed_document {
    p {
      font-weight: 400 !important;
    }
  }
}
.mgp_release_section {
  .user_kyc_section {
    .circular-progress-main {
      //position: absolute;
      //top: 47%;
      //left: 47%;
      //z-index: 8;

      position: fixed;
      z-index: 999;
      inset: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //background: #1d1f2b;
      background: rgba(0, 0, 0, 0.7);
      .MuiBox-root.css-1o8dslu {
        .css-ol7wxs-MuiTypography-root {
          font-size: 14px !important;
        }
      }
    }
  }
}

.circular-progress-label {
  z-index: 20;
  position: absolute;
  width: 421px;
  margin: 0 auto;
  text-align: center;
  color: white !important;
  .css-1rn30mb-MuiCircularProgress-root {
    color: #b9b2b2 !important;
  }
  .MuiTypography-root.MuiTypography-caption.css-ol7wxs-MuiTypography-root {
    color: white !important;
  }
}

// //////////////////////////////////// Responsive Page ////////////////////
@media screen and (min-width: 320px) and (max-width: 425px) {
  // Mobile View
  .css-1j11r67 {
    width: 300px !important;
    overflow: hidden;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  // Tablet View
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  // Laptop View
}
.mgp_release_box{
  .filter_days_details_dropDown{
    margin: 0 10px 0 0 !important;
  }
}
