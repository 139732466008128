.inner-header {
  //background-color: #0C0D13;
  padding: 0px 40px;
  max-height: 86px;
  min-height: 64px;
  z-index: 49;
  color: #ffffff;
  background-color: #1E1E1E;
  border-left: 1px solid;
  position: sticky;
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 100%;
  top: 0;
  //    max-width: calc(100% - 300px);
  z-index: 11;
  @media screen and (max-width: 550px) {
    max-height: 96px;
  }
  @media screen and (min-width: 320px) and (max-width: 820px) {
    padding: 0 28px;
  }
  .main_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 86px;
    @media screen and (max-width: 550px) {
      display: block;
      height: 96px;
    }
    &_title {
      color: #f3eeee;
      font-weight: 600;

    }
    color: $white;
    h4 {
      font-size: 23px !important;
      font-weight: 600 !important;
      letter-spacing: 0;
      line-height: 44px !important;
      display: block;
      text-transform: capitalize;
      @media screen and (max-width: 550px) {
        display: flex ;
        font-size: 17px !important;
      }

      //@media screen and (max-width: 514px) {
      //  display: none;
      //}
    }
    .title {
      display: none;
      @media screen and (max-width: 514px) {
        display: block;
        font-weight: 700;
        inline-size: 200px;
      }
    }
    .change-password {
      a {
        color: #0277bd;
        font-weight: 800;
        text-decoration: none;
      }
    }
  }

  .hamburger {
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;

    @media screen and (min-width: 992px) {
      display: none !important;
    }
  }
}

.inner_list_details .list_item {
  height: 40px !important;
  padding: 10px 0;
  padding-left: 64px !important;
}

@media screen and (min-width: 320px) and (max-width: 660px) {
  .inner-header{
  padding: 0 20px;
}
}

.header_profile{
  width: auto;
  padding-left: 10px;
  line-height: 0;
  justify-content: flex-end!important;
align-items: center;
  display: flex;
  @media screen and (max-width: 550px) {
    width: 100%;
  }
  .profile {
    @extend .d_flex;
    @extend .align_items_center;

    &_name {
      font-size: 14px;

      text-transform: uppercase;
      color: white;
      font-weight: 600;
      margin-right: 1rem;
      display: inline-block;
      text-align: right;
      line-height: normal;
      p{
        font-size: 12px;
        text-transform: none;
        line-height: normal;
        @media screen and (max-width: 414px) {
        display: none;
        }
      }
    }

    &_pic {
      height: 42px;
      max-width: 42px;
      width: 100%;
      object-fit: cover; object-position: center;
      border: 2px solid white;
      border-radius: 100%;
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
    cursor: pointer;
      margin-right: 0.6rem;
      img {
        height: 100%;
        max-width: 100%;
        width: 100%;
        display: block;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }
  .drop_down {
    position: relative;
    &_list {
      position: absolute;
      min-width: 11.4rem;
      background: #ffffff;
      top: 29px;
      right: -2rem;
      border-radius: 10px;
      padding: 20px 17px;
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.15);
      z-index: 9;

      &::before {
        content: '';
        background: url(../images/drop_down_ceross_icon.png) no-repeat;
        width: 1.3rem;
        height: 1.3rem;
        display: block;
        position: absolute;
        top: -8px;
        right: 22px;
      }

      &.notification_modal {
        min-width: 325px;
        padding: 20px 20px 17px 17px;
        min-height: auto;
        height: auto;
        max-height: 326px;
        overflow-y: auto;
        margin: 0;
        z-index: 999;

        &::before {
          display: none;
        }

        li {
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          font-size: 11px;
          line-height: 15px;
          color: #000000;
          text-align: left;
          padding-bottom: 15px;
          margin: 0;
          border-bottom: 1px solid #F1F1F1;

          &:not(:first-child) {
            padding: 15px 0;
          }

          &:last-child {
            border: unset;
            padding-bottom: 0;
          }

          a {
            @extend li;
            text-decoration: none;
            font-weight: 600;
          }

          &.notification_modal_flex {
            align-items: flex-start;
          }
        }
      }

      li {
        text-align: center;
        position: relative;
        a {
          font-size: 14px;
          line-height: 1.5rem;
          color: #0277bd;
          text-decoration: none;
          font-weight: 700;
          &.active {
            color: $black;

            span {
           display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -10px;
            }
          }
        }
      }

      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  .notification_block {
    position: relative;
    cursor: pointer !important;
    margin-left: 10px;
    line-height: normal;
    span {
      font-weight: 600;
      font-size: 9px;
      letter-spacing: 0.3125px;
      text-transform: capitalize;
      color: #FFFFFF;
      background: #F84747;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      min-width: 18px;
      // height: 18px;
      padding: 2px;
      display: block;
      text-align: center;
      border-radius: 100%;
      position: absolute;
      bottom: -3px;
      right: -9px;
      cursor: pointer;
      display: grid;
      place-items: center;
      line-height: 12px;
    }
  }
}

.noti-content::-webkit-scrollbar {height: .5rem; width: .5rem;}/* width */
.noti-content::-webkit-scrollbar-track {background: $light_silver; }/* Track */
.noti-content::-webkit-scrollbar-thumb {background: $dark_silver; }/* Handle */
.noti-content::-webkit-scrollbar-thumb:hover {background: $silver_gray; }/* Handle on hover */

.dropdown-menu{
  position: absolute;
  z-index: 1000;
  //display: none;
  min-width: 24rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
}

.notifications-popup{
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translate(0px,100px);
  -ms-transform: translate(0px,100px);
  box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
  border: 0;
  position: absolute;
  inset: 30px 13px auto auto;
  margin: 0px;
  background: white;
  transform: translate(0px, 38px);
  .no_notification_text{
    font-family: "Inter", sans-serif;
  padding:  30px 12px;
    font-size: 14px;
  }
  .topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center;
    font-size: 12px;
    height: 40px;
    line-height: 32px;
    padding: 0 12px;
    .notification-title {
      color: #333;
      display: block;
      float: left;
      font-size: 14px;
    }
    .clear-noti {
      color: #ea5455;
      float: right;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .noti-content {
    width: 384px;
    overflow-y: auto;
    position: relative;
    height: 370px;
     .notification-list {
       li {
         margin-top: 0;
         border-bottom: 1px solid #f5f5f5;
         font-size: 14px;
         a{
           display: block;
           padding: 12px;
           border-radius: 2px;
           transition: all .2s ease;
           text-decoration: none;
           .d-flex {
             display: flex !important;
             align-items: center;
           }
           .avatar {
             background-color: transparent;
             color: #fff;
             display: inline-block;
             font-weight: 500;
             height: 40px;
             line-height: 38px;
             text-align: center;
             text-decoration: none;
             text-transform: uppercase;
             vertical-align: middle;
             width: 40px;
             position: relative;
             white-space: nowrap;
             margin: 0 10px 0 0;
             border-radius: 50%;
             position: relative;
             img{
               width: 100%;
               height: 100%;
               -o-object-fit: cover;
               object-fit: cover;
               border-radius: 50%;
             }
           }
           .flex-grow-1 {
             flex-grow: 1!important;
           }
           .noti-details {
             color: #989c9e;
             margin: 0;
             line-height: normal;
             font-size: 13px;
              .noti-title {
               color: #333;
               font-weight: 500;
             }
           }
        .notification-time {
             font-size: 12px;
             line-height: 1.35;
             color: #637381;
            display: flex;
            align-items: center;
          .dots_white {
            width: 5px;
            height: 5px;
            background: lightgray;
            border-radius: 50%;
            margin: 0 5px;
          }
           }
         }
       }
     }
  }

}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
  font-size: 12px;
  height: 46px;
  line-height: 32px;
  padding: 0 12px;
  text-align: right;
  padding-top: 10px;
}
a{
  text-decoration: none;
}

.notification_loader{
  .MuiCircularProgress-colorPrimary {
    color: #3c6fff;
    position: absolute;
    left: 44%;
    top: 43%;
  }
}
.disabled_notification{
  background: lightgrey;
  cursor: not-allowed;
}


.right_side_profile{
  display: flex;
  @media screen and (min-width: 320px) and (max-width: 820px) {
    margin-right: -23px;
  }
}

.sidebar_closeBtn{
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
  svg{
    path{
      fill: white;
    }
  }
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}

.css-197mko7{
  @media screen and (min-width: 320px) and (max-width: 820px) {
    margin: 32px 20px !important;
  }
}