* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: normal;
}
body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .left_side_wrapper {
    width: 80% !important;
  }
}

//input{
//  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
//}

@import "~react-datepicker/dist/react-datepicker.css";
@import "assets/Style/app";


tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root, .table_loader_details{
  position: relative;
}

.table_loader_details{
  height: 91vh;
  overflow: hidden;
}

.Testing{
  position: absolute;
  /* position: fixed; */
  display: block;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255);
  z-index: 99;
  text-align: center;

}
.right_side_layout_details{
  position: relative;
}