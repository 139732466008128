.select2-container--above,.select2-container--below,span.select2.select2-container.select2-container--default{
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder,.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
}
.select2-container--default .select2-selection--single{
  height: 44px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
  line-height: 42px !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 4px !important;
  height: 40px !important;
  padding: 10px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px !important;
}
.restrict_geo_section .select2-container--default .select2-selection--single{
  border: 1px solid #cfd8e3 !important;
}
.select2-container--default .select2-selection--single{
  border: 1px solid #cfd8e3 !important;
}
span.select2-container.select2-container--default.select2-container--open{
  z-index: 99999;
}