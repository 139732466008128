.dashboard_wrapper {
  display: flex;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 991px) {
    .left_side_wrapper {
      transform: translateX(-100%);
      position: fixed;
      transition: 0.3s;

    }
    &.active {
      .left_side_wrapper {
        transform: translateX(0);
      }
    }
  }
  .left_side_wrapper {
    width: 100%;
    max-width: 310px;
    height: 100%;
    overflow: hidden;
    z-index: 999;
    height: 100%;
    bottom: 0;
    top: 0;
    background:$light_black;
    color: $white;
    .logout_btn{
      border-top: 1px solid !important;
      svg{
        color: #97a6ba;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        fill: #97a6ba;
      }
    }
    .gp_left-bar{
      position: relative;
      height: 100%;
      padding-top: 10px;
    }
    .left_side_inner_sec{
      .tab_sidebar_details{
        height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
        //padding-top: 16px
      }
      .tab_sidebar_details::-webkit-scrollbar {height: .2rem; width: .4rem;}/* width */
      .tab_sidebar_details::-webkit-scrollbar-track {background: #BFC6CF; }/* Track */
      .tab_sidebar_details::-webkit-scrollbar-thumb {background: #3E4A58; }/* Handle */
      .tab_sidebar_details::-webkit-scrollbar-thumb:hover {background: #495568; }/* Handle on hover */
    }
    .icon-left-side,.Game_Icon {
      svg {
        color: #97a6ba;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        fill: #97a6ba;
      }
    }
    .Game_Icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: #cfd8e3;
      font-family: "Inter", sans-serif ;
    }
    .list_item{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 24px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
      transition: background-color 375ms cubic-bezier(0.25, 0.8, 0.25, 1);
      color: #cfd8e3;
    }
  }
  .right_side_wrapper {
    width: 100%;
    max-width: calc(100% - 300px);
    background: $login_Bg;
    position: relative;
    overflow: auto;
    @media screen and (max-width: 991px) {
      max-width: 100% !important;
    }
  }
};

//------------------------------------- dashboard Page-------------------------------------------------------------
.dashboard-section {
  background: #00082e;
  .Logo {
    //width: 730px;
    height: 150px;
    object-fit: contain;
    margin: 0 auto;
  }
  h6 {
    font-size: 30px ;
    font-weight: 700 ;
    text-align: center;
    padding-top: 35px;
    margin-top: 0;
    font-family: "Inter", sans-serif ;
    color: white;
    @media screen and (min-width: 320px) and (max-width: 820px) {
      font-size: 25px ;
    }
  }
};

.side_logo img {
  height: 75px;
  margin-bottom: 15px;
  object-fit: cover;
  width: 100%;
}

.css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
  display: -webkit-inline-box !important;
}

@media screen and (min-width: 320px) and (max-width: 820px) {
  .tab .tab_bg_white {
    background: #ffffff;
    padding: 0 ;
  }
  .add_game_details .add_game_popup_content{
    padding: 0 !important;
  }
  .add_game_popup{
    .add_game_section_content_form{
      display: flex !important;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      .formData{
        width: 100% !important;
        margin-bottom: 10px;
      }
      .new_game_section{
        width: 100%;
      }
    }
    .select_game_platform_value{
      flex-wrap: wrap;
      .tab01{
        margin: 0 !important;
      }
      .popup_form_checkbox{
        margin-left: 0 !important;
      }
    }
  };
  .common_checkbox_details{
    margin-bottom: 10px;
  }
  .report_analytics_details .report_analytics{
    flex-wrap: wrap;
    .left_side_analytics{
      margin-right: 0 !important;
    }
    .right_side_analytics{
      margin-left: 0 !important;
      margin-top: 20px;
    }
  }
  .analytics_filter  .filter_inner_tab_info .filter_export_date_dropdown .filter_days_details_dropDown{
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .how_to_start_title.d_flex.justify_content_between.align_items_center{
    flex-wrap: wrap;
  }

}