.level_popup_form_field_left{
  .dropdown_content_list_field {
    display: flex;
    align-items: center;
    margin-top: 15px;
    input[type='checkbox'] {
      width: 15px;
      height: 15px;
      cursor: pointer;
      min-height: 15px;
      margin: 0;
      margin-right:10px;
    }
  }
}

.bank_upi_section{
  .profile .user_profile_pic img{
    border-radius: 0;
  }
}

.withdrawal_processing_section{
  .filter_days_details_dropDown.gst-dropdown_width{
    width: 100%;
  }
}

.transaction_id_section{
  .user_profile .user_profile_pic img{
    height: 200px !important;
  }
}

.withdrawal_details_view{
  .headToHead_popup_details{
    border: none;
    .formData label{
      width: 38%;
    }
  }
}
.withdrawal_approved_details_popup{
 .formData label{
   width: auto !important;
  }
  .information_value{
    margin-left: 20px;
  }
  .profile .user_profile_pic img{
    border-radius: 0 ;
  }
}
.withdrawal_type_details_popup{
  .formData label{
    width: auto !important;
  }
}

.outer-box.withdrawal_details_table{
  margin: 32px 32px;
  padding: 30px 20px;
  span.edit_btn.orderId_details{
    font-size: 12px ;
  }
.withdrawal_left_section{
  width: 309px;
  p{
    margin-bottom: 7px;
  }
}
  table .table_cell_thead{
    min-width: 53px !important;
  }
}
.user_profile_pic_image_withdrawal{
  .user_profile_pic{
    margin-bottom: 2px !important;
  }
  .edit_btn {
    color: #0277BD !important;
    font-weight: 500 !important;
    line-height: 21px;
    margin-right: 5px;
    cursor: pointer;
    text-decoration: none;
  }
}

